import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import * as styles from '@/components/hero/hero.module.scss'

type PropsType = {
    image: GatsbyTypes.Maybe<Pick<GatsbyTypes.ContentfulAsset, 'title' | 'gatsbyImageData'>>
    title: Readonly<string>
    descriptions: ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>>
}

const Index: React.FC<PropsType> = ({ image, title, descriptions }: PropsType) => (
  <div className={styles.hero}>
    {image && (
      <GatsbyImage className={styles.image} alt={image.title ?? ''} image={image.gatsbyImageData!} />
    )}
    <div className={styles.details}>
      <h1 className={styles.title}>{title}</h1>
      {descriptions && <p className={styles.content}>{descriptions}</p>}
    </div>
  </div>
)

export default Index
