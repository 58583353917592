import { graphql, PageProps } from 'gatsby'
import React from 'react'

import ArticlePreview from '@/components/article-preview'
import Hero from '@/components/hero'
import Layout from '@/components/layout'
import Seo from "@/components/seo";

const RootIndex: React.FC<PageProps<GatsbyTypes.TopPageQuery>> = ({ data }) => {
  if (data.allContentfulHero.nodes.length === 0) {
    return <></>
  }

  const hero = data.allContentfulHero.nodes[0]
  const posts = data.allContentfulBlog.nodes

  const heroTitle: string = hero.title ?? ''
  const heroImageUrl: string = hero?.image?.resize?.src ?? ''
  const heroImage = hero?.image

  return (
    <Layout title={heroTitle}>
      <Seo
        title={heroTitle}
        description={hero?.descriptions?.join(' ') ?? ''}
        image={heroImageUrl}
        meta={[]}
      />
      <Hero
          image={heroImage}
          title={heroTitle}
          descriptions={hero?.descriptions ?? []}
      />
      <ArticlePreview posts={posts} />
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query TopPage{
    allContentfulHero(limit: 1) {
      nodes {
        title
        descriptions
        image {
          title
          gatsbyImageData
            resize {
                src
            }
        }
      }
    }
    allContentfulBlog(sort: {fields: createdAt}) {
      nodes {
        id
        title
        shortDescription
        body {
          raw
        }
        image {
          gatsbyImageData
        }
        tags
        publishedAt
      }
    }
  }
`
