import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import React from 'react'

import * as styles from '@/components/article-preview/article-preview.module.scss'
import Container from '@/components/container'
import Tags from '@/components/tags'

type PropsType = {
    posts: ReadonlyArray<Pick<GatsbyTypes.ContentfulBlog, 'id' | 'title' | 'shortDescription' | 'tags' | 'publishedAt'>>
}

const Index: React.FC<PropsType> = ({ posts }) => {
    if (!posts) return null
    if (!Array.isArray(posts)) return null

    const formatDate = (publishedAt: string): string => {
      const time: Date = new Date(publishedAt)
      return `${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()}`
    }
    return (
        <Container as={"div"}>
            <ul className={styles.articleList}>
                {posts.map((post) => {
                    return (
                        <li key={post.id}>
                            <Link to={`/blog/${post.id}`} className={styles.link}>
                                <GatsbyImage alt={post.title} image={post.image.gatsbyImageData} />
                                <h2 className={styles.title}>{post.title}</h2>
                            </Link>
                            <div>{post.shortDescription}</div>
                            <div className={styles.meta}>
                                <small className="meta">{formatDate(post.publishedAt)}</small>
                                <Tags tags={post.tags ?? []} />
                            </div>
                        </li>
                    )
                })}
            </ul>
        </Container>
    )
}

export default Index
